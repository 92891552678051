// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src631572691/src/sso/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src631572691/src/sso/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("/codebuild/output/src631572691/src/sso/src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-profile-js": () => import("/codebuild/output/src631572691/src/sso/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

